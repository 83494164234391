import React from 'react';
import styled from 'styled-components';

const Inner = styled.ul`
    counter-reset: steps-counter;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: relative;

    @media (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }
`;

const Item = styled.li`
    color: #fff;
    counter-increment: steps-counter;
    position: relative;
    margin-bottom: 30px;

    &:before {
        color: var(--green);
        content: "0" counter(steps-counter);
        display: block;
        font-size: 26px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 20px;
    }

    &:after {
        background: var(--lightest-navy);
        content: '';
        height: 1px;
        position: absolute;
        right: 0;
        top: 10px;
        width: calc(100% - 50px);
    }
`;

const Steps = () => (
  <section id="steps">
    <h2 className="numbered-heading">
        Jak Vám mohu pomoci
    </h2>

    <Inner>
      <Item>
        <h3>
          Konzultace
        </h3>
        <p>Poslechnu si vaše představy, zahrnu Vás celou řadou dotazů, upozorním Vás na možná úzká místa vašich představ, a nastíním teoretické řešení vašeho projektu.</p>
      </Item>
      <Item>
        <h3>
          Odborná specifikace
        </h3>
        <p>Připravím si kompletní workflow k vašemu řešení, včetně technické dokumentace potřebné k užívání řešení.</p>
      </Item>
      <Item>
        <h3>
          Instalace a zaškolení
        </h3>
        <p>Nasadím mnou připravené řešení dle technické dokumentace, a naučím Vás, případně i vaše kolegy jak mnou připrevné řešení používat.</p>
      </Item>
      <Item>
        <h3>
          Dohled a údržba
        </h3>
        <p>Postarám se o to, aby Vám mnou nasazené řešení fungovalo bez problému, poskytnu pravidelné reporty ve Vámi zvoleném časovém rozsahu.</p>
      </Item>
    </Inner>

  </section>
);

export default Steps;
