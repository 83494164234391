import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="m44.97233,46.44047c-2.32298,0.74626 -3.97467,0.53379 -5.88199,0.804c-2.31854,0.14934 -4.59665,0.12138 -5.58961,0.22478c-0.09211,2.82587 -0.21677,6.01064 -0.1933,13.44476l-4.76576,0.08599l0,-30.7l10.75,0c6.4,0 10.4,2.85 10.4,8.35c0,1.65 -0.37455,3.88843 -1.30484,5.09914c-1.00205,1.40453 -1.409,2.00781 -3.4145,2.69133zm-11.38066,-3.24047l5.65,0c3.55,0 5.45,-1.35 5.45,-4.25c0,-2.9 -1.9,-4.15 -5.45,-4.15l-5.65,0l0,8.4z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
